<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="wrapper">
    <div class="container loading-opacity" *ngIf="hiddenUserInfo">
        <p class="description text-center">
            {{ "auth.reauthorize.its-you" | myTranslate: { hiddenUserInfo: hiddenUserInfo } }}
        </p>

        <div class="buttons flex-center">
            <button class="base primary" (click)="reauthorize()">
                {{ "auth.reauthorize.button-send" | myTranslate }}
            </button>
        </div>

        <!-- LOGOUT -->
        <a class="description text-center logout" (click)="logout()">
            <p>{{ "auth.reauthorize.its-not-you" | myTranslate }}</p>
        </a>
    </div>
    <a class="contact" [href]="'mailto:' + config?.common?.contact_email">{{ config?.common?.contact_email ?? ''}}</a>
</div>
<div class="copyright-container" *ngIf="config?.default_page.copyright_text">
    <p [innerHTML]="config?.default_page.copyright_text"></p>
</div>
