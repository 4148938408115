import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IResult, UAParser } from "ua-parser-js";

@Injectable({ providedIn: "root" })
export class A2hsService {
  deferredPrompt = window["GamificationApp"].eventBeforeInstallPrompt;
  deviceDetected: IResult = null;
  appCanBeInstalled = new BehaviorSubject<{ pwa: boolean; onlyIOS: boolean }>({ pwa: false, onlyIOS: false });

  constructor() {
    this.deviceDetected = new UAParser(window.navigator.userAgent).getResult();
    //listen for event from src/scripts/events-listeners.js
    window.addEventListener("a2hsVisibilityChanged", () => {
      this.checkPWAisAvailable();
    });
    this.checkPWAisAvailable();
  }

  addApplicationToHomeScreen() {
    this.deferredPrompt.prompt();
    this.deferredPrompt = null;
    this.appCanBeInstalled.next({ pwa: false, onlyIOS: false });
  }

  checkPWAisAvailable() {
    if (window["NativeInterface"]) {
      this.appCanBeInstalled.next({ pwa: false, onlyIOS: false });
      return;
    }
    if (
      this.deviceDetected.os.name === "iOS" &&
      (this.deviceDetected.browser.name === "Mobile Safari" || this.deviceDetected.browser.name === "Safari")
    ) {
      this.appCanBeInstalled.next({ pwa: false, onlyIOS: true });
    }
    if (window["GamificationApp"].eventBeforeInstallPrompt) {
      this.deferredPrompt = window["GamificationApp"].eventBeforeInstallPrompt;
      this.appCanBeInstalled.next({ pwa: true, onlyIOS: false });
    }
  }
}
