import { Directive, Injectable, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { GetGameRankResultsResponse } from "../../api/custom/interfaces/response/get-game-rank-results.response.interface";
import { ApiCoreGameRanksService } from "../../api/custom/services/api-game-ranks.service";
import { RANK_CONFIG } from "../../base/custom/dialogs/ranking-game-details/config/rank.config";
import { LEVEL_FILTER_KEY } from "../../consts/custom/rank-filter-key.const";
import { RankGame } from "../../interfaces/custom/rank-game.interface";

@Directive()
@Injectable()
export abstract class AbstractRankDetailsGameComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractRankDetailsGameComponent>;
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: {
    rankId: number;
    rankGroup: number;
  };
  limit = 100;
  STOCK_VIEW = STOCK_VIEW;
  rank: RankGame;
  rankResults: GetGameRankResultsResponse;
  RANK_CONFIG = R.clone(RANK_CONFIG.DEFAULT);

  FILTERS = [
    { label: "Sklep", index: 1, filterKey: LEVEL_FILTER_KEY.BRANCH, hideIdTitles: [2], visible: null },
    { label: "Kraj", index: 3, filterKey: LEVEL_FILTER_KEY.COUNTRY, hideIdTitles: [], visible: null },
  ];
  activeFilter;

  ngOnInit() {
    this.getGameRank();
  }

  changeFilter(filter) {
    this.activeFilter = this.searchFilter(filter.index);
    this.getRank();
  }

  searchFilter(index: number) {
    return this.FILTERS.find(filter => filter.index === index);
  }

  setVisibleFilters() {
    let activeFilter;

    if (R.path(["parameters", "krs_id"], this.rank)) {
      this.FILTERS = [
        { label: "Sklep", index: 1, filterKey: LEVEL_FILTER_KEY.BRANCH, hideIdTitles: [2], visible: null },
        { label: "Region", index: 2, filterKey: LEVEL_FILTER_KEY.REGION, hideIdTitles: [], visible: null },
      ];
    }

    this.FILTERS.forEach(filter => {
      if (filter.hideIdTitles.length > 0) {
        filter.visible = filter.hideIdTitles.indexOf(this.data.rankGroup) === -1;
      } else {
        filter.visible = true;
      }

      if (filter.visible && !activeFilter) {
        activeFilter = filter;
      }
    });

    this.changeFilter(activeFilter);
  }

  getRank() {
    if (this.rank.parameters && this.rank.parameters["aggregated-branches"]) {
      this.getGameRankBranchesResult();
    } else {
      this.getGameRanksResults();
    }
  }

  getGameRank() {
    this.apiCoreGameRanksService
      .getGameRank({
        rank_edition_id: this.data.rankId,
      })
      .subscribe(
        resp => {
          this.rank = resp;

          if (this.rank.parameters && this.rank.parameters["aggregated-branches"]) {
            this.RANK_CONFIG = R.clone(RANK_CONFIG.AGGREGATED_BRANCHES);
            this.FILTERS = [{ label: "Kraj", index: 3, filterKey: LEVEL_FILTER_KEY.COUNTRY, hideIdTitles: [], visible: null }];
          }
          this.setVisibleFilters();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  getGameRanksResults() {
    this.apiCoreGameRanksService
      .getGameRanksResults({
        rank_edition_id: this.rank.rank_edition_id,
        limit: this.limit,
        ...this.setRequestDataScope(),
      })
      .subscribe(
        resp => {
          this.rankResults = resp;
          this.afterGetGameRankRequest();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  getGameRankBranchesResult() {
    this.apiCoreGameRanksService
      .gameRankBranchesResult({
        rank_edition_id: this.rank.rank_edition_id,
        limit: this.limit,
        ...this.setRequestDataScope(),
      })
      .subscribe(
        resp => {
          this.rankResults = resp;
          this.afterGetGameRankRequest();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  setRequestDataScope() {
    const scope = {
      branch: false,
      region: false,
    };

    switch (this.activeFilter.filterKey) {
      case LEVEL_FILTER_KEY.BRANCH:
        scope.branch = true;
        break;

      case LEVEL_FILTER_KEY.REGION:
        scope.region = true;
        break;
    }

    return scope;
  }

  afterGetGameRankRequest() {
    this.replacePlaceholders();

    this.verifyAwardVisibility();
  }

  replacePlaceholders() {
    const regex = new RegExp(`%([a-zA-Z0-9_]+)`, "g");

    Object.keys(this.rank.parameters || {}).forEach(key => {
      this.rankResults.results?.forEach(item => {
        let value = this.rank.parameters[key];

        if (typeof value === "string") {
          let result = this.getRegexValue(regex, value);

          while (result) {
            value = value.replace(`%${result}`, item[result]);
            result = this.getRegexValue(regex, value);
          }

          item[key] = value;
        }
      });
    });
  }

  // #31481 note 30
  verifyAwardVisibility() {
    for (let i = 1; i <= 3; i++) {
      if (this.rank.parameters?.[`min_value${i}`]) {
        const minValue = this.rank.parameters?.[`min_value${i}`];

        if (this.rankResults.my_position[`value${i}`] < minValue) {
          this.rankResults.my_position.product_prizes = [];
          this.rankResults.my_position.currency_prizes = [];
        }

        this.rankResults.results.forEach((result) => {
          if (result[`value${i}`] < minValue) {
            result.product_prizes = [];
            result.currency_prizes = [];
          }
        })
      }
    }
  }

  getRegexValue(regex: RegExp, string: string) {
    const results = regex.exec(string);
    return results && results[1];
  }

  close() {
    this.matDialogRef.close();
  }
}
